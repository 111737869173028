@import url('https://fonts.googleapis.com/css2?family=Handlee&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200');

/* For Google Icons */
.material-symbols-outlined {
  font-variation-settings:
  'FILL' 0,
  'wght' 400,
  'GRAD' 0,
  'opsz' 24;
}
* {
  font-family: 'Handlee', 'cursive';
}
:root, html {
  background-color: darkslategray;
}
body, .App {
  max-height: 100vh;
  overflow: hidden;
}
a {
  color: goldenrod;
}
.App {
  max-width: 90%;
  margin: 0 auto;
  padding: 40px 0 20px 0;
  color: white;
  height: 100vh;
  max-height: 100vh;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  overflow: hidden;
}
header {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;  
  margin: 0 0 30px;
  width: 100%;
}
#banner p {
  font-size: 24px;
  text-align: center;
  max-width: 600px;
}
input {
  font-size: 24px;
  margin: 10px;
  background-color: darkslategray;
  color: goldenrod;
}
#enterTime{
  background-color: darkolivegreen;
}
#enterTime::placeholder{
  color: white;
}

::placeholder {
  opacity: 0.3;
}
#swal2-validation-message{
  background-color: darkslategray;
  font-size: 24px;
  color: goldenrod;
}
#tasks {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 20px;
  justify-content: space-around;
  width: 100%;
  max-width: fit-content;
}
#getTasks, #addTask {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
}
button {
  background-color: darkgoldenrod;
  padding: 20px 30px;
  border: 4px solid goldenrod;
  border-radius: 4px;
  font-size: 24px;
  color: yellow;
}
button:hover {
  background-color: goldenrod;
  border-color: darkgoldenrod;
}
button:active {
  background-color: rgb(129, 95, 10);
  border-color: darkgoldenrod;
}
.timer {
  display: flex;
  flex-flow: row nowrap;
  align-items: start;
  justify-content: start;
}
.timerButtons {
  font-size: 40px;
  padding: 5px;
}
.labels {
  margin-bottom: 20px;
}
.focusMode {
  width: 300px!important;
  height: 300px!important;
}
.focusMode a  {
  display: none;
}
.focusMode .dueDate  {
  display: none;
}
.focusMode .labels  {
  display: none;
}
.addLabel {
  margin-right: 5px;
}
.checkbox:hover, .addLabel:hover, .calendar {
  cursor: pointer;
}
.taskName, .labels {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: flex-start;
}
.taskName .checkbox {
  margin-right: 15px;
}
.taskName .name {
  cursor: text;
}
.taskName .close, .labels .delete {
  cursor: pointer;
  margin-left: auto;
  padding-left: 20px;
}
.complete {
  background-color: rgb(47, 59, 26)!important;
  border-color: darkgoldenrod!important;
  color: grey!important;
  text-decoration: line-through!important;
}
.complete .delete {
  display: none;
}
#banner {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
}
main {
  width: 100%;
  height: 100%;
}
.resize-drag {
  display: inline-block;
  width: fit-content;
  min-width: 300px;
  height: fit-content;
  min-height: 300px;
  border: 4px solid goldenrod;
  border-radius: 8px;
  padding: 20px;
  margin: 1rem;
  background-color: darkolivegreen;
  color: white;
  font-size: 20px;
  font-family: 'Handlee', cursive;
  position: absolute;
  overflow: hidden;

  touch-action: none;

  /* This makes things *much* easier */
  box-sizing: border-box;
}
.taskDescription {
  cursor: text;
  margin-bottom: 12px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden; 
}
.delete {
  margin-left: 20px;
}
.delete:hover {
  cursor: pointer;
}
.dragBar {
  margin: 0;
  padding: 0;
  line-height: 0;
}
#organize {
  display: none;
  flex: row nowrap;
  align-items: center;
  justify-content: center;
}
#countdown {
  font-size: 24px;
  margin: 0 20px;
}